<template>
  <a-modal :width="680" :visible="visible" :confirm-loading="loading" :title="'导入物流信息'"
    :body-style="{ paddingBottom: '8px' }" @update:visible="updateVisible" @ok="save">
    <a-form ref="form" :model="form" :rules="rules" :label-col="{ md: { span: 6 }, sm: { span: 24 } }"
      :wrapper-col="{ md: { span: 18 }, sm: { span: 24 } }">
      <a-row :gutter="16">
        <a-upload :file-list="fileList" :before-upload="beforeUpload">
          <div v-if="fileList.length < 1">
            <a-button>
              <upload-outlined></upload-outlined>
              文件选择
            </a-button>
          </div>
        </a-upload>
      </a-row>
      <a-row :gutter="16">
        <a target="_blank" href="/static/files/物流信息导入模板.xlsx">物流信息导入模板下载</a>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
import * as dictDataApi from '@/api/sys/dictData'
import * as pointsOrderApi from '@/api/jyyw/pointsOrder'
import regions from '@/utils/regions'
export default {
  name: 'import-Edit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  data() {
    return {
      form: Object.assign({}, this.data),
      logDict: [],
      cityData: regions,
      checked: true,
      // 表格搜索条件
      where: {},
      fileList: [],
      page: 1,
      limit: 10,
      list: [],
      // 发货状态字典项
      delivery: [],
      // 日期范围选择
      daterange: []
    }
  },
  created() {
    this.queryDeliveryRoles()
  },
  mounted() { },
  watch: {
    daterange() {
      if (this.daterange && this.daterange.length === 2) {
        this.where.createTimeStart = this.daterange[0]
        this.where.createTimeEnd = this.daterange[1]
      } else {
        this.where.createTimeStart = null
        this.where.createTimeEnd = null
      }
    }
  },
  methods: {
    checkedChange(e) {
      console.log('e', e.event)
    },
    save() {
      if (this.fileList.length === 0) {
        this.$message.error('请选择文件')
        return
      }
      var formData = new FormData()
      formData.append('file', this.fileList[0])
      pointsOrderApi
        .imports(formData)
        .then((res) => {
          if (res.code === 0) {
            this.fileList = []
            this.$message.success(res.msg)
            this.updateVisible(false)
            this.$emit('done')
          }
        })
        .catch((e) => { })
    },
    beforeUpload(file) {
      this.fileList = [file]
      return false
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
      this.$emit('update:data', this.form)
    },
    // 发货状态
    queryDeliveryRoles() {
      dictDataApi
        .querydictdata('deliveryStatus')
        .then((res) => {
          if (res.code === 0) {
            this.delivery = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    }
  }
}
</script>

<style lang="less" scoped>
</style>
