import request from '@/utils/request'

// api地址
const api = {
  all: '/jyyw/pointsOrder',
  save: '/jyyw/pointsOrder',
  update: 'jyyw/pointsOrder/update',
  info: '/jyyw/pointsOrder/',
  delete: '/jyyw/pointsOrder/',
  deleteBatch: '/jyyw/pointsOrder/batch',
  resultDictdata: '/sys/dictdata',
  page: '/jyyw/pointsOrder/page',
  delivery: '/jyyw/pointsOrder/delivery',
  receipt: '/jyyw/pointsOrder/receipt/edit',
  export: '/jyyw/pointsOrder/delivery/export',
  import: '/jyyw/pointsOrder/delivery/import'
}

/**
 * 根据id查询
 * @param id id
 * @returns {Promise<Object>}
 */
export function getById(id) {
  return new Promise((resolve, reject) => {
    request.get(api.info + id).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 查询列表
 * @param params 参数
 * @returns {Promise<Array>}
 */
export function page(params) {
  return new Promise((resolve, reject) => {
    request.get(api.page, {
      params: params
    }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 查询列表
 * @param params 参数
 * @returns {Promise<Array>}
 */
export function all(params) {
  return new Promise((resolve, reject) => {
    request.get(api.all, {
      params: params
    }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 根据id删除
 * @param id id
 * @returns {Promise<>}
 */
export function deleteById(id) {
  return new Promise((resolve, reject) => {
    request.delete(api.delete + id).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 批量删除
 * @param id id
 * @returns {Promise<>}
 */
export function deleteBatch(data) {
  return new Promise((resolve, reject) => {
    request.delete(api.deleteBatch, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 添加或修改
 * @param data 参数
 * @returns {Promise<>}
 */
export function save(data, isUpdate) {
  if (isUpdate) {
    return new Promise((resolve, reject) => {
      request.post(api.update, data).then(res => {
        resolve(res.data)
      }).catch(e => {
        reject(e)
      })
    })
  } else {
    return new Promise((resolve, reject) => {
      request.post(api.save, data).then(res => {
        resolve(res.data)
      }).catch(e => {
        reject(e)
      })
    })
  }
}
/**
 * 查询列表
 * 分页查询申请举荐结果字典项
 * @param params 参数
 * @returns {Promise<Array>}
 */
export const resultDictdata = (val) => {
  return new Promise((resolve, reject) => {
    const params = {
      dictCode: val
    }
    request.get(api.resultDictdata, {
      params: params
    }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
/**
 * 添加订单发货或修改订单发货
 * @param data 参数
 * @returns {Promise<>}
 */
export function delivery(data) {
  return new Promise((resolve, reject) => {
    request.post(api.delivery, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
/**
 * 修改收货人信息
 * @param data 参数
 * @returns {Promise<>}
 */
export function receipt(data) {
  return new Promise((resolve, reject) => {
    request.post(api.receipt, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
/**
 * 批量导出订单发货地址
 * @param data 参数
 * @returns {Promise<>}
 */
export function exports(params) {
  return new Promise((resolve, reject) => {
    request.get(api.export, {
      params: params,
      responseType: 'blob'
    }).then(res => {
      resolve(res)
    }).catch(e => {
      reject(e)
    })
  })
}
/**
 * 批量导入订单快递单号
 * @param data 参数
 * @returns {Promise<>}
 */
export function imports(data) {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
  return new Promise((resolve, reject) => {
    request.post(api.import, data, config
    ).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
